import React from "react";
import SearchResultPage from "../../../templates/search-results-template";

const SearchResultAuctionResiSales = (props) => {

    const fullpath = props.location.pathname
    const location = props.location
    const fullpathone = props.location
    // const searchParams = parseSearchUrl("sales", fullpath);

    return (
        <React.Fragment>
            <SearchResultPage 
                locationname="/auction-residential-property/for-sale/" 
                pcategorytype="auction_residential" 
                ptype="sales" 
                ptypetag="for-sale" 
                pstatustype="" 
                fullpathone={fullpathone} 
                fullpathname={fullpath}
                {...props}
                tag={"auction"}
            />
        </React.Fragment>
    );
};

export default SearchResultAuctionResiSales;
